import camelcaseKeys from 'camelcase-keys';
import { UnAuthorizedError } from '@utils/APICustomErrors';

const simpleFetch = (endpoint, options = {}) => {
  const {
    hasHTML = false,
    authorization,
    searchToken,
    headers = {},
    useCamelCase = true
  } = options;
  const headerOptions = {
    ...(authorization ? { authorization: `Bearer ${authorization}` } : null),
    ...headers
  };

  if (searchToken) {
    headerOptions.searchtoken = `Bearer ${searchToken}`;
  }

  return fetch(endpoint, { headers: headerOptions })
    .then((res) => {
      if (!res.ok) {
        if (res.status === UnAuthorizedError.statusCode) {
          throw new UnAuthorizedError();
        }
      }

      return res;
    })
    .then((res) => (hasHTML ? res.text() : res.json()))
    .then((res) => (useCamelCase ? camelcaseKeys(res, { deep: true }) : res));
};

export default simpleFetch;
